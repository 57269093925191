import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Image from "next/image";
import teamMap, { Team } from "../../lib/utils/teamMap";

export default function TeamsPopover({ team }: { team: Team | null }) {
  return (
    <div className="z-10">
      <Menu as="div" className="relative inline-block">
        <div>
          <Menu.Button className="w-10 h-10 border-solid border-2 border-white cursor-pointer shadow-md">
            {team ? (
              <Image
                src={`/img/afl/${team.short_name.toLowerCase()}.jpg`}
                alt={`${team.full_name} logo`}
                layout="intrinsic"
                width={36}
                height={36}
              />
            ) : (
              <div className="bg-black opacity-40 w-full h-full"></div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="grid grid-cols-3 gap-x-3 gap-y-4 absolute right-0 mt-2 w-52 p-4 origin-top-right rounded-md bg-white shadow-lg text-center outline-none">
            {Object.keys(teamMap).map((teamAbbr) => (
              <Menu.Item key={teamAbbr.toLowerCase()}>
                {({ active }) => (
                  <a
                    href={`/${teamAbbr.toLowerCase()}`}
                    className={`${
                      active
                        ? "outline outline-2 outline-offset-1 outline-blue-500"
                        : ""
                    } w-10 h-10 border-solid border-2 mx-auto`}
                  >
                    <Image
                      src={`/img/afl/${teamMap[
                        teamAbbr
                      ].short_name.toLowerCase()}.jpg`}
                      alt={`${teamMap[teamAbbr].full_name} logo`}
                      layout="intrinsic"
                      width={36}
                      height={36}
                    />
                  </a>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
