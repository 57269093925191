import create from "zustand";
import { persist } from "zustand/middleware";
import teamMap from "../utils/teamMap";
import { Player } from "./GameStore";

const lineupKeys = [
  "LBP",
  "FB",
  "RBP",
  "LHB",
  "CHB",
  "RHB",
  "LW",
  "C",
  "RW",
  "LHF",
  "CHF",
  "RHF",
  "LFP",
  "FF",
  "RFP",
  "R",
  "RR",
  "RV",
];
type Lineup = {
  [K in typeof lineupKeys[number]]: string | null;
};

interface PersistentStore {
  instructionsDismissed: boolean;
  saveInstructionsDismissed: (value: boolean) => void;
  lineupsByAbbr: { [key: string]: Lineup };
  saveLineupByAbbr: (teamAbbr: string, lineup: Lineup) => void;
  getLineupByAbbr: (teamAbbr: string) => Lineup;
}

const usePersistentStore = create<PersistentStore>(
  persist(
    (set, get) => ({
      instructionsDismissed: false,
      saveInstructionsDismissed: (isDismissed: boolean) =>
        set(() => ({
          instructionsDismissed: isDismissed,
        })),

      lineupsByAbbr: (() => {
        let lineups: { [key: string]: Lineup } = {};
        Object.keys(teamMap).forEach((teamAbbr) => {
          lineups[teamAbbr] = newLineup();
        });
        return lineups;
      })(),
      saveLineupByAbbr: (teamAbbr: string, lineup: Lineup) => {
        //console.log("saving", { [teamAbbr]: lineup });
        return set((prevState) => ({
          lineupsByAbbr: Object.assign(prevState.lineupsByAbbr, {
            [teamAbbr]: lineup,
          }),
        }));
      },
      getLineupByAbbr: (teamAbbr: string) => {
        return get().lineupsByAbbr[teamAbbr];
      },
    }),
    {
      name: "best22",
      getStorage: () => localStorage,
      merge: (persistedState, currentState) => {
        return { ...currentState, ...persistedState };
      },
    }
  )
);

export default usePersistentStore;

export function newLineup(): Lineup {
  return {
    LBP: null,
    FB: null,
    RBP: null,
    LHB: null,
    CHB: null,
    RHB: null,
    LW: null,
    C: null,
    RW: null,
    LHF: null,
    CHF: null,
    RHF: null,
    LFP: null,
    FF: null,
    RFP: null,
    R: null,
    RR: null,
    RV: null,
  };
}
