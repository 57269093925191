import React, { useContext, useEffect, useState } from "react";
import SelectModal from "../../components/modals/SelectModal";
import useGameStore, { Player } from "../../lib/store/GameStore";
import usePersistentStore, { newLineup } from "../../lib/store/PersistentStore";

const FieldView = () => {
  return (
    <div className="row flex flex-wrap -mx-4">
      <div className="col-12 col-lg-6 grow-0 shrink-0 basis-auto w-full">
        <div className="ground mx-auto my-0 max-w-md w-full h-[650px] bg-center bg-no-repeat bg-cover bg-[url('/img/field.png')] ">
          <div className="positions mx-auto my-0 px-6 max-w-sm">
            <div className="fullbacks flex pt-20">
              <div className="grow shrink-0 basis-4"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="LBP" displayName="BP" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="FB" displayName="FB" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="RBP" displayName="BP" />
              </div>
              <div className="grow shrink-0 basis-4"></div>
            </div>
            <div className="halfbacks flex pt-7">
              <div className="shrink-0 basis-0">
                <PlayerCell pos="LHB" displayName="HBF" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="CHB" displayName="CHB" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="shrink-0 basis-0">
                <PlayerCell pos="RHB" displayName="RHB" />
              </div>
            </div>
            <div className="mids flex justify-between pt-5 h-[11.0rem]">
              <div className="flex flex-col justify-center shrink-0 basis-0">
                <PlayerCell pos="LW" displayName="W" />
              </div>
              <div className="flex flex-col justify-between items-center grow shrink-0 basis-0">
                <div className="flex flex-row justify-center grow items-start">
                  <PlayerCell pos="R" displayName="R" />
                  <div className="grow shrink-0 basis-8"></div>
                  <PlayerCell pos="RR" displayName="RR" />
                </div>
                <div className="flex flex-row justify-center grow items-end">
                  <PlayerCell pos="C" displayName="C" />
                  <div className="grow shrink-0 basis-8"></div>
                  <PlayerCell pos="RV" displayName="RV" />
                </div>
              </div>
              <div className="flex flex-col justify-center shrink-0 basis-0">
                <PlayerCell pos="RW" displayName="W" />
              </div>
            </div>
            <div className="halfforwards flex pt-5">
              <div className="shrink-0 basis-0">
                <PlayerCell pos="LHF" displayName="HFF" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="shrink-0 basis-0">
                <PlayerCell pos="CHF" displayName="CHF" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="shrink-0 basis-0">
                <PlayerCell pos="RHF" displayName="HFF" />
              </div>
            </div>
            <div className="fullforwards flex pt-7">
              <div className="grow shrink-0 basis-4"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="LFP" displayName="FP" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="FF" displayName="FF" />
              </div>
              <div className="grow shrink-0 basis-0"></div>
              <div className="grow shrink-0 basis-0">
                <PlayerCell pos="RFP" displayName="FP" />
              </div>
              <div className="grow shrink-0 basis-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldView;

const PlayerCell = ({
  pos,
  displayName,
}: {
  pos: string;
  displayName: string;
}) => {
  let [isOpen, setIsOpen] = useState(false);
  let [player, setPlayer] = useState<Player | null>(null);

  const [team, playersByTeam, playersById] = useGameStore((state) => [
    state.team,
    state.playersByTeam,
    state.playersById,
  ]);
  const [saveLineupByAbbr, getLineupByAbbr] = usePersistentStore((state) => [
    state.saveLineupByAbbr,
    state.getLineupByAbbr,
  ]);

  const lineup = team ? getLineupByAbbr(team.abbr) : newLineup();

  function makeSelection(newPlayer: Player) {
    if (newPlayer.playerId === player?.playerId) {
      setPlayer(null);
      lineup[pos] = null;
    } else {
      setPlayer(newPlayer);
      lineup[pos] = newPlayer ? newPlayer.playerId : null;
    }

    if (team) {
      saveLineupByAbbr(team.abbr, lineup);
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const playerId = lineup[pos];
    setPlayer(playerId ? playersById[playerId] : null);
  }, [playersByTeam, lineup]);

  const imgUrl =
    player &&
    `url('${`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/2023014/${player.playerId.substring(
      4
    )}.png?i10c=img.resize(scale_height:0.6)`}')`;

  return (
    <div>
      <div
        className="position is-selected cursor-pointer h-[3.75rem] w-[3.75rem] mx-auto my-0 relative"
        onClick={openModal}
      >
        <div
          className={`absolute w-full h-full rounded-xl bg-white glass border border-white ${
            player ? "border-opacity-20" : "border-opacity-100"
          }`}
        ></div>

        <div
          className="absolute h-[3rem] w-[3.75rem] bg-cover bg-no-repeat"
          style={{ backgroundImage: imgUrl || undefined }}
        ></div>
        {player ? (
          <div className="absolute h-4 w-full bottom-0 bg-black rounded-b-xl flex justify-center items-center">
            <div className="text-white font-semibold text-names uppercase mb-[1px] px-1.5 whitespace-nowrap overflow-hidden overflow-ellipsis tracking-wide">
              {player?.surname}
            </div>
          </div>
        ) : (
          <div className="absolute h-full w-full leading-[4rem] font-serif text-[#21500F]">
            {/* <button type="button" onClick={openModal} className="w-full h-full"> */}
            {displayName}
            {/* </button> */}
          </div>
        )}
      </div>
      <SelectModal
        key={pos}
        pos={pos}
        isOpen={isOpen}
        closeModal={closeModal}
        currentPlayer={player}
        setPlayer={makeSelection}
      />
    </div>
  );
};
