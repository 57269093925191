import React from "react";
import HowToPlayButton from "../../components/modals/HowToPlayButton";
import TeamsPopover from "../../components/modals/TeamsPopover";
import { Team } from "../../lib/utils/teamMap";

export function Hero({ team }: { team: Team | null }) {
  return (
    <div className="text-left max-w-lg pt-6 pb-1 px-1 md:pt-11 md:pb-4 mx-auto flex justify-between items-center">
      <h2 className="font-400 leading-none pt-1 tracking-tight text-white text-[2rem] font-serif">
        Best22
      </h2>

      {/* <HowToPlayButton
        team={team}
        modalTitle={`Pick your Best 22!`}
        modalBody={`Select the 22 players you think are in the best side for ${team.full_name} and share with friends`}
      /> */}
      <TeamsPopover team={team} />
    </div>
  );
}
