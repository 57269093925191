export interface Team {
  id: number;
  full_name: string;
  name: string;
  short_name: string;
  abbr: string;
  close: string[];
  colors: string[];
  state: string;
}

const teamMap: { [key: string]: Team } = {
  ADEL: {
    id: 10,
    full_name: "Adelaide Crows",
    name: "Adelaide Crows",
    short_name: "ADE",
    abbr: "ADEL",
    close: ["CD_T10", "CD_T110"],
    colors: ["red", "yellow", "blue"],
    state: "SA",
  },
  BL: {
    id: 20,
    full_name: "Brisbane Lions",
    name: "Brisbane Lions",
    short_name: "BRL",
    abbr: "BL",
    close: ["CD_T20", "CD_T1000"],
    colors: ["maroon", "yellow", "blue"],
    state: "QLD",
  },
  CARL: {
    id: 30,
    full_name: "Carlton",
    name: "Carlton",
    short_name: "CAR",
    abbr: "CARL",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["blue"],
    state: "VIC",
  },
  COLL: {
    id: 40,
    full_name: "Collingwood",
    name: "Collingwood",
    short_name: "COL",
    abbr: "COLL",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["black", "white"],
    state: "VIC",
  },
  ESS: {
    id: 50,
    full_name: "Essendon",
    name: "Essendon",
    short_name: "ESS",
    abbr: "ESS",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "black"],
    state: "VIC",
  },
  FRE: {
    id: 60,
    full_name: "Fremantle",
    name: "Fremantle",
    short_name: "FRE",
    abbr: "FRE",
    close: ["CD_T60", "CD_T150"],
    colors: ["purple", "white"],
    state: "WA",
  },
  GEEL: {
    id: 70,
    full_name: "Geelong Cats",
    name: "Geelong Cats",
    short_name: "GEE",
    abbr: "GEEL",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["white", "blue"],
    state: "VIC",
  },
  GCFC: {
    id: 1000,
    full_name: "Gold Coast Suns",
    name: "Gold Coast Suns",
    short_name: "GCS",
    abbr: "GCFC",
    close: ["CD_T20", "CD_T1000"],
    colors: ["red", "yellow"],
    state: "QLD",
  },
  GWS: {
    id: 1010,
    full_name: "GWS Giants",
    name: "GWS Giants",
    short_name: "GWS",
    abbr: "GWS",
    close: ["CD_T1010", "CD_T160"],
    colors: ["grey", "orange", "white"],
    state: "NSW",
  },
  HAW: {
    id: 80,
    full_name: "Hawthorn",
    name: "Hawthorn",
    short_name: "HAW",
    abbr: "HAW",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["brown", "yellow"],
    state: "VIC",
  },
  MELB: {
    id: 90,
    full_name: "Melbourne",
    name: "Melbourne",
    short_name: "MEL",
    abbr: "MELB",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "blue"],
    state: "VIC",
  },
  NMFC: {
    id: 100,
    full_name: "North Melbourne",
    name: "North Melbourne",
    short_name: "NTH",
    abbr: "NMFC",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["white", "blue"],
    state: "VIC",
  },
  PORT: {
    id: 110,
    full_name: "Port Adelaide",
    name: "Port Adelaide",
    short_name: "PTA",
    abbr: "PORT",
    close: ["CD_T10", "CD_T110"],
    colors: ["black", "white", "teal"],
    state: "SA",
  },
  RICH: {
    id: 120,
    full_name: "Richmond",
    name: "Richmond",
    short_name: "RIC",
    abbr: "RICH",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["yellow", "black"],
    state: "VIC",
  },
  STK: {
    id: 130,
    full_name: "St Kilda",
    name: "St Kilda",
    short_name: "STK",
    abbr: "STK",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "white", "black"],
    state: "VIC",
  },
  SYD: {
    id: 160,
    full_name: "Sydney Swans",
    name: "Sydney Swans",
    short_name: "SYD",
    abbr: "SYD",
    close: ["CD_T1010", "CD_T160"],
    colors: ["red", "white"],
    state: "NSW",
  },
  WCE: {
    id: 150,
    full_name: "West Coast Eagles",
    name: "West Coast Eagles",
    short_name: "WCE",
    abbr: "WCE",
    close: ["CD_T60", "CD_T150"],
    colors: ["yellow", "blue"],
    state: "WA",
  },
  WB: {
    id: 140,
    full_name: "Western Bulldogs",
    name: "Western Bulldogs",
    short_name: "WBD",
    abbr: "WB",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "white", "blue"],
    state: "VIC",
  },
};

export default teamMap;
