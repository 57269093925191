import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import useGameStore, { Player, TeamLines } from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

export default function SelectModal({
  pos,
  isOpen,
  closeModal,
  setPlayer,
  currentPlayer,
}: {
  pos: string;
  isOpen: boolean;
  closeModal: () => void;
  setPlayer: (player: Player) => void;
  currentPlayer: Player | null;
}) {
  const [team, playersByTeamAndPos] = useGameStore((state) => [
    state.team,
    state.playersByTeamAndPos,
  ]);
  const teamPlayersByPos: TeamLines | null = team
    ? playersByTeamAndPos[team.abbr]
    : null;

  const [getLineupByAbbr] = usePersistentStore((state) => [
    state.getLineupByAbbr,
  ]);
  const lineup = team ? getLineupByAbbr(team.abbr) : null;
  const selectedPlayers: string[] = lineup
    ? Object.values(lineup).filter((s): s is string => Boolean(s))
    : [];

  function onClick(player: Player) {
    setPlayer(player);
    closeModal();
  }

  function PlayerButton(
    p: Player,
    selectedPlayers: string[],
    currentPlayer: Player | null,
    onClick: (player: Player) => void
  ) {
    return (
      <button
        key={p.playerId}
        className={`${
          selectedPlayers.includes(p.playerId) ? "bg-amber-300" : "bg-slate-300"
        }
        ${
          currentPlayer?.playerId === p.playerId ? "outline" : ""
        } px-3 py-1 mr-1 mb-1 rounded-lg`}
        onClick={() => onClick(p)}
      >
        {`${p.givenName.substring(0, 1)}. ${p.surname}`}
      </button>
    );
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-serif font-normal"
                  >
                    Select {pos}
                  </Dialog.Title>
                  <div className="mt-3"> */}
                  <div>
                    <p className="mb-1 font-semibold uppercase tracking-wide text-sm text-gray-900">
                      Defenders
                    </p>
                    <p className="text-sm text-gray-700">
                      {teamPlayersByPos?.defs.map((p) =>
                        PlayerButton(p, selectedPlayers, currentPlayer, onClick)
                      )}
                    </p>
                    <p className="mt-2 mb-1 font-semibold uppercase tracking-wide text-sm text-gray-900">
                      Midfielders
                    </p>
                    <p className="text-sm text-gray-700">
                      {teamPlayersByPos?.mids.map((p) =>
                        PlayerButton(p, selectedPlayers, currentPlayer, onClick)
                      )}
                    </p>
                    <p className="mt-2 mb-1 font-semibold uppercase tracking-wide text-sm text-gray-900">
                      Rucks
                    </p>
                    <p className="text-sm text-gray-700">
                      {teamPlayersByPos?.rucks.map((p) =>
                        PlayerButton(p, selectedPlayers, currentPlayer, onClick)
                      )}
                    </p>
                    <p className="mt-2 mb-1 font-semibold uppercase tracking-wide text-sm text-gray-900">
                      Forwards
                    </p>
                    <p className="text-sm text-gray-700">
                      {teamPlayersByPos?.fwds.map((p) =>
                        PlayerButton(p, selectedPlayers, currentPlayer, onClick)
                      )}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
