import React from "react";
import { useEffect, useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import HowToPlayButton from "../../components/modals/HowToPlayButton";
import { Hero } from "./Hero";
import { HowToPlayBody } from "./HowToPlayBody";
import { Footer } from "../../components/Footer";
import FieldView from "./FieldView";
import { Team } from "../../lib/utils/teamMap";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

function Best22({ team }: { team: Team | null }) {
  const [fetchPlayers, setupLevel] = useGameStore((state) => [
    state.fetchPlayers,
    state.setupLevel,
  ]);
  const [adsLoaded, setAdsLoaded] = useState<boolean>(false);

  // Set up game
  useEffect(() => {
    const init = async () => {
      await setupLevel(team);
      await fetchPlayers?.();
    };
    init();

    if (!adsLoaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      setAdsLoaded(true);
    }
  }, []);

  return (
    <div className="relative">
      {/* <header className=" bg-primary">
          <div className="max-w-xl mx-auto flex justify-center md:justify-center px-1 py-1">
            <HowToPlayButton
              modalTitle="Guess the mystery player!"
              modalBody={<HowToPlayBody />}
            />
            <div className="uppercase w-auto pt-1 md:px-7 md:py-3 text-white font-bold text-xs md:text-sm">
              <span className="inline-block">Best 22</span>
            </div>
          </div>
        </header> */}
      <main className="container text-center mx-auto px-4 pb-20 md:pb-32 antialiased">
        <Hero team={team} />
        <FieldView />
      </main>
      <Footer />
    </div>
  );
}

export default Best22;
