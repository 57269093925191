import create from "zustand";
import teamMap, { Team } from "../utils/teamMap";

async function fetchPlayers(url: string) {
  const data = await (await fetch(url)).json();

  return data;
}

export interface Player {
  playerId: string;
  dataId: string;
  givenName: string;
  surname: string;
  heightCm: number;
  weightKg: number;
  jumperNumber: number;
  position: string;
  team: string;
  dateOfBirth: string;
  draftYear: string;
  debutYear: string;
}

export interface TeamLines {
  defs: Player[];
  mids: Player[];
  rucks: Player[];
  fwds: Player[];
}

interface GameState {
  initError: boolean;
  setInitError: (value: boolean) => void;
  playersById: { [key: string]: Player };
  playersByTeam: { [key: string]: Player[] };
  playersByTeamAndPos: {
    [key: string]: TeamLines;
  };
  fetchPlayers: () => Promise<void>;
  team: Team | null;
  setTeam: (team: Team | null) => void;
  analyticsGameName: string;
  setAnalyticsGameName: (name: string) => void;
  shareGameName: string;
  setShareGameName: (name: string) => void;
  shareGameUrl: string;
  setShareGameUrl: (url: string) => void;
  setupLevel: (team: Team | null) => Promise<void>;
  clearState: () => void;
}

const useGameStore = create<GameState>((set, get) => ({
  initError: false,
  setInitError: (value) => set({ initError: value }),

  playersById: {},
  playersByTeam: {},
  playersByTeamAndPos: {},
  fetchPlayers: async () => {
    try {
      console.log("fetching players");
      const playersById: { [key: string]: Player } = await fetchPlayers(
        "json/players.json"
      );

      let playersByTeam: { [key: string]: Player[] } = {};
      let playersByTeamAndPos: {
        [key: string]: TeamLines;
      } = {};
      Object.keys(teamMap).forEach((teamAbbr) => {
        const teamPlayers = Object.values(playersById)
          .filter((p) => p.team === teamAbbr)
          .sort((a, b) => parseInt(a.draftYear) - parseInt(b.draftYear));

        playersByTeam[teamAbbr] = teamPlayers;
        playersByTeamAndPos[teamAbbr] = {
          defs: teamPlayers.filter(
            (p) =>
              p.position === "KEY_DEFENDER" || p.position === "MEDIUM_DEFENDER"
          ),
          mids: teamPlayers.filter(
            (p) =>
              p.position === "MIDFIELDER" || p.position === "MIDFIELDER_FORWARD"
          ),
          rucks: teamPlayers.filter((p) => p.position === "RUCK"),
          fwds: teamPlayers.filter(
            (p) =>
              p.position === "MEDIUM_FORWARD" || p.position === "KEY_FORWARD"
          ),
        };
      });
      set({ playersById: playersById });
      set({ playersByTeam: playersByTeam });
      set({ playersByTeamAndPos: playersByTeamAndPos });

      // console.log("playersById", playersById);
      // console.log("playersByTeam", playersByTeam);
      // console.log("playersByTeamAndPos", playersByTeamAndPos);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
  },

  team: null,
  setTeam: (team) => set({ team: team }),

  analyticsGameName: "",
  setAnalyticsGameName: (name) => set({ analyticsGameName: name }),

  shareGameName: "",
  setShareGameName: (name) => set({ shareGameName: name }),

  shareGameUrl: "",
  setShareGameUrl: (url) => set({ shareGameUrl: url }),

  setupLevel: async (team) => {
    get().setTeam(team);
    get().setAnalyticsGameName("Best22");
    get().setShareGameName("Best22");
    get().setShareGameUrl("https://best22.team");

    get().setInitError(false);
  },

  clearState: () => {
    console.log("clearing state");
    // set((state) => ({
    //   players: [],
    // }));
  },
}));

export default useGameStore;
